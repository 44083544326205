/* Font sizes */
$pixels-for-a-rem: 10px; // 1rem

$font-size-xxxxlarge: 2.4rem; // 24px
$font-size-xxxlarge: 2.2rem; // 22px
$font-size-xxlarge: 2rem; // 20px
$font-size-xlarge: 1.8rem; // 18px
$font-size-large: 1.6rem; // 16px
$font-size-medium: 1.4rem; // 14px
$font-size-small: 1.3rem; // 13px
$font-size-xsmall: 1.2rem; // 12px
$font-size-xxsmall: 1.1rem; // 11px
$font-size-xxxsmall: 1rem; // 10px
$font-size-xxxxsmall: 0.8rem; // 8px

$font-size-default: $font-size-medium;

/* Responsive breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$orientation-landscape: landscape;
$orientation-portrait: portrait;

$bg-menu: #ffffff;
$bg-infor-odd: rgb(249, 250, 251);
$bg-search: #4c79ff;
$bg-infor-even: #eff9fa;

$logo-color: #4a2068;
$highlight-color: #4c79ff;
$primary-color: #000000;
$secondary-color: #ffffff;
$error-text-color: #e91224;
$success-text-color: #1b5e20;
$panel-border-color: rgb(237, 239, 242);

$title-font: "Title font";
