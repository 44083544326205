@import 'variables';
@import 'functions';

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-in-range($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-min(breakpoint-next($name, $breakpoints), $breakpoints);
  @if $min and $max {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-device-mobile() {
  @media (min-width: 300px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: $orientation-portrait) {
    @content;
  }
}

@mixin media-device-tablet() {
  @media (min-width: 481px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: $orientation-landscape) {
    @content;
  }
  @media (min-width: 601px) and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: $orientation-portrait) {
    @content;
  }
}

@mixin media-device-desktop() {
  @media (min-width: 1024px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: $orientation-landscape) {
    @content;
  }
  @media (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin media-laptop-mdpi() {
  @media (min-width: 1201px) and (max-width: 1310px) {
    @content;
  }
}

@mixin fontSize($size) {
  font-size: $size; // Fallback in px
  font-size: calculateRem($size);
}
