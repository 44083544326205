@import 'variables';
@import 'functions';
@import 'mixins';

* {
  // margin: 0;
  // padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  // overflow-y: hidden;
  // default browser font size: 16px = 1em => 10px = 0.625em
  // $pixels-for-a-rem: 10px (defined in variables.scss)
  // If you want to change font-size here you might want to update the variable [$pixels-for-a-rem] also
  // font-size: 0.625em; // => 0.625em = 1rem
}

body {
  background-color: $bg-menu;
  position: relative;
  // overflow: hidden;
  // font-family: Arial, Helvetica, sans-serif;
}

#root,
body,
html {
  // width: 100%;
  // height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body > iframe[style*='2147483647'] {
  display: none;
}

p {
  margin-bottom: 0;
  font-size: 1.4rem;
}

input {
  text-transform: uppercase;
}

@font-face {
  font-family: $title-font;
  src: url('../fonts/opal.ttf');
}

.text-error,
.msg-info,
.error-feedback {
  color: $error-text-color;
}

.negative-error {
  background-color: $error-text-color !important;
  color: $secondary-color !important;
}

.text-success {
  color: $success-text-color;
}

.table-header {
  text-align: right;

  i {
    margin-right: 5px;
  }
}

.external-filter {
  margin-bottom: 20px;
  .p-dropdown {
    width: 200px;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.w-40 {
  width: 40%;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-self-center {
  display: flex;
  align-self: center;
}

.flex-item-center {
  display: flex;
  align-items: center;
}

.text-bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.highlight-search {
  color: #e91224;
  font-weight: bold;
}

.text-underline {
  cursor: pointer;
  text-decoration: underline;
}

.text-pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.d-block {
  display: block;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow-auto {
  overflow: auto;
}

.loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 19px;
  animation: pulse 1s infinite ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
}

.text-primary {
  color: #1d86d8;
}

.danger-bg {
  background-color: #f1606c !important;
  color: #ffffff;
}

.primary-bg {
  background-color: #007ad9 !important;
  color: #ffffff;

  a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
}

.warning-bg {
  background-color: #ffba01 !important;
  color: #000000;
}

.white-bg {
  background-color: #ffffff !important;
  color: #333;
}

.no-border {
  border: none !important;
}

.p-tag {
  font-weight: 700;
  @include fontSize(12px);
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  text-transform: uppercase;
  color: #ffffff;

  &.p-success {
    background-color: #34a835;
  }
  &.p-primary {
    background-color: #007ad9;
  }
  &.p-danger {
    background-color: #e91224;
  }
  &.p-warning {
    background-color: #ffba01;
    color: #000000;
  }
}

.w-165 {
  width: 165px !important;
}

.w-190 {
  width: 190px !important;
}

.w-210 {
  width: 210px !important;
}

.mapboxgl-marker {
  position: relative;

  .map-icon {
    width: 29px;
    height: 29px;
  }
}
.map-marker {
  color: #007ad9;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: -25px;
}

.v-love,
.v-like,
.v-haha,
.v-wow,
.v-sad,
.v-angry {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  display: inline-block;
  margin: 3px 3px 0px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
}

.v-love {
  background-image: url('../images/love.png');
}

.v-like {
  background-image: url('../images/like.png');
}

.v-haha {
  background-image: url('../images/haha.png');
}

.v-wow {
  background-image: url('../images/wow.png');
}

.v-sad {
  background-image: url('../images/sad.png');
}

.v-angry {
  background-image: url('../images/angry.png');
}

.p-button {
  cursor: pointer;
}

.p-invalid {
  border-color: $error-text-color !important;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 5px;
  color: #e91224;
}

.mentions-input__suggestions {
  .mentions-input__suggestions__list {
    max-height: 50vh;
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}

.placeholder-content {
  overflow: hidden;
  background: #000;
  position: relative;

  // Animation
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8; // Fallback
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px; // Animation Area
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
